<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="">
          <div class=" special-box white-bg" style="float: unset;">
            <div class="div_special">
              <h3 class=" special-title">考试专题</h3>
              <div class="position">
                <a href="/index">
                  首页
                </a>
                &gt;
                <a href="/examTypeList">
                  考试计划
                </a>
                &gt;
                <a href="javascript:void(0);" style="color: #0d5799">
                  {{flmc}}
                </a>
              </div>
            </div>
            <div class="div_Main">
              <div class="div_Main_contanier">
                <div class="Left">
                  <div class="Main_contanier_title title-bg-blue">
                    <img src="@/assets/images/index/icon/voice.png">
                    招考公告
                  </div>
                  <div class="List_">
                    <div class=" mt-2">
                        <div class="List-box flexList" v-for="(item,i) in listBox" :key="i">
                          <div class="center-list-title flexList" @click="openNews(item.newsId)">
                            <i class="iconfont icon-xiaofangkuai"
                              style="color: #c6200d;"></i>
                            <div class="line1 center-list-name">{{item.newsTitle}}</div>
                            <div class="center-list-time">{{item.createTime}}</div>
                          </div>
                        </div>


                    </div>
                  </div>
                </div>

                <div class="Right">
                  <div class="div_Main_Top_Right_Title">考生快速入口</div>
                  <div class="quick_entrance">
                    <a href="" target="_blank">
                      <div class="div_Main_Top_Right" v-for="(item,i) in rightNavList" :key="i" :class="{
                    'active': rightNavActive == i
                  }"><img :src="item.imgUrl" :alt="item.name">{{item.name}}</div>
                    </a>
                  </div>

                </div>
              </div>
              <div class="div_Main_contanier">
                <div class="Left">
                  <div class="Main_contanier_title title-bg-blue">
                    <a href="javascript:void(0);" @click="toCjwt()" style="color: white;">
                      <img src="@/assets/images/index/icon/wt.png">
                      常见问题
                    </a>
                  </div>
                  <div class="List_">
                    <div class=" mt-2">
                      <a href="/newsDetails">
                        <div class="List-box flexList" v-for="(item,i) in questionList" :key="i">
                          <div class="center-list-title flexList"><i class="iconfont icon-xiaofangkuai"
                              style="color: #c6200d;"></i>
                            <div class="line1 center-list-name">{{item.newsTitle}}</div>
                            <div class="center-list-time">{{item.createTime}}</div>
                          </div>
                        </div>
                      </a>


                    </div>
                  </div>
                </div>

                <div class="Right ">
                  <div class="div_Main_Top_Right_Title">工作时间安排</div>
                  <div class="txt-news-con">
                    <ul class="news-ul">
                     <li v-for="(item,index) in timeList" :key="index">
                        <div class="newstxtli line1">
                          {{item.title}}
                        </div>
                        <div class="timebox">
                          <div class="timecon">
                            {{item.kssj}} <span v-if="item.kssj&&item.jssj">至</span> {{item.jssj}}
                          </div>
                        </div>
                      </li>



                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>










      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "./index-header.vue"
  import Footer from "./index-footer.vue"
  import Navbar from "./navbar.vue"

  import { getNewsListPage,getExam } from "@/api/web/web.js";
  export default {
    name: "index",
    data() {
      return {
        rightNavActive: 0,
        listBox: [
        ],
        questionList: [],
        rightNavList: [
                {
            imgUrl: require('@/assets/images/index/icon/user.png'),
            name: "登录注册",
          },
          {
            imgUrl: require('@/assets/images/index/icon/baom.png'),
            name: "网上报名",
          },
          {
            imgUrl: require('@/assets/images/index/icon/print.png'),
            name: "准考证打印",
          },
          {
            imgUrl: require('@/assets/images/index/icon/search.png'),
            name: "成绩查询",
          },
          {
            imgUrl: require('@/assets/images/index/icon/score.png'),
            name: "成绩复核申请",
          },
          {
            imgUrl: require('@/assets/images/index/icon/send.png'),
            name: "证书邮寄",
          }
        ],
        timeList: [
        ],
        flmc: "",
        flbm: ""
      }
    },
    components: {
      Header,
      Navbar,
      Footer
    },
    methods: {
      /**
       * 获取新闻
       */
      getNewsList(params) {
        getNewsListPage(params).then((res) => {
          if (res.status) {
            let list = res.data
            for(var i=0;i<list.length;i++) {
              list[i].createTime = list[i].createTime.substring(0,10).replaceAll("-","/")
            }
            this.listBox = list
          }
        });
      },
      /**
       * 获取常见问题
       * @param params
       */
      getQuestion(params) {
        getNewsListPage(params).then((res) => {
          if (res.status) {
            let list = res.data
            for(var i=0;i<list.length;i++) {
              list[i].createTime = list[i].createTime.substring(0,10).replaceAll("-","/")
            }
            this.questionList = list
          }
        });
      },
      /**
       * 获取该类别下最新的一条考试信息
       */
      getExamLast() {
        getExam({limit: 1, flbm: this.flbm}).then((res) => {
          if (res.status) {
            let list = res.data
            let timeList = []
            if (list != null && list.length > 0) {
              if (list[0].bmkssj != null) {
                timeList.push({title: '报名', kssj: list[0].bmkssj.substring(0,16).replaceAll("-","/"), jssj: list[0].bmjssj.substring(0,16).replaceAll("-","/")})
              }
              if (list[0].cskssj != null) {
                timeList.push({title: '资格初审', kssj: list[0].cskssj.substring(0,16).replaceAll("-","/"), jssj: list[0].csjssj.substring(0,16).replaceAll("-","/")})
              }
              if (list[0].jfkssj != null) {
                timeList.push({title: '报名缴费', kssj: list[0].jfkssj.substring(0,16).replaceAll("-","/"), jssj: list[0].jfjssj.substring(0,16).replaceAll("-","/")})
              }
              if (list[0].zkzdykssj != null) {
                timeList.push({title: '准考证打印', kssj: list[0].zkzdykssj.substring(0,16).replaceAll("-","/"), jssj: list[0].zkzdyjssj.substring(0,16).replaceAll("-","/")})
              }
            }
            this.timeList = timeList
          }
        });
      },
      /**
       * 打开新闻详情
       * */
      openNews(newsId) {
        let routeUrl = this.$router.resolve({
          path: "/newsDetails",
          query: {
            newsId: newsId,
          },
        });
        window.open(routeUrl.href, "_blank");
      },
      /**
       * 跳转常见问题
       */
      toCjwt() {
        window.location.href = "/questionList";
      }
    },
    mounted() {
      this.flmc = this.$route.query.flmc
      this.flbm = this.$route.query.flbm
      // 加载该分类下的公告
      this.getNewsList({isPaging:0, limit: 8, flbm: this.$route.query.flbm})
      // 加载指定的常见问题
      this.getQuestion({isPaging:0, limit: 8, indexName: "cjwt"})
      // 加载时间安排
      this.getExamLast();
    }
  };
</script>

<style>
  @import '~@/assets/css/common.css';
  @import '~@/assets/css/index.css';
  @import '~@/assets/css/list.css';
</style>
<style type="text/css" scoped>
</style>
